<template>
  <div v-if="mediaId">
    <media-viewer
      ref="mediaViewer"
      :data="media"
      :scene-index="sceneIndex"
      @error="loadingError"
    />
  </div>
</template>

<script>
import MediaViewer from '@/components/medias/MediaViewer'

export default {
  title: 'Interactivo',
  name: 'MediaDetail',
  components: {
    MediaViewer
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasErrors) {
      next()
      return
    }

    if (this.ignoreRouteHooks) {
      next()
      return
    }

    this.$buefy.dialog.confirm({
      message: 'Estás a punto de abandonar el interactivo, ¿Deseas continuar?',
      cancelText: 'No',
      confirmText: 'Si',
      onConfirm: () => {
        this.$refs.mediaViewer.stopAll()
        this.$store.dispatch('orientationToChange', false)
        next()
      },
      onCancel: () => {
        next(false)
      }
    })
  },
  data: function () {
    return {
      mediaId: null,
      sceneIndex: 0,
      media: null,
      hasErrors: false
    }
  },
  computed: {
    userToken() {
      return this.$store.state.token
    }
  },
  watch: {
    userToken() {
      this.loadContent()
    },
    '$screen.landscape'() {
      this.checkOrientation()
    }
  },
  beforeMount() {
    this.mediaId = this.$router.currentRoute.params.id

    if (this.$router.currentRoute.hash.indexOf('#') === 0) {
      this.sceneIndex = parseInt(this.$router.currentRoute.hash.replace('#', ''))

      if (Number.isNaN(this.sceneIndex)) {
        this.sceneIndex = 0
      }
    }
    this.checkOrientation()
  },
  mounted() {
    if (this.mediaId) {
      this.loadContent()
    }
  },
  methods: {
    async loadContent() {
      try {
        await this.$store.dispatch('initLoading')
        const bookId = this.$route.query?.book
        this.media = await this.$api.media.getMedia(this.mediaId, bookId)

        this.setPageTitle(this.media.title)

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')

        await this.handleContentError(error)
      }
    },
    checkOrientation() {
      if (!this.$screen.landscape) {
        this.$store.dispatch('orientationToChange', true)
      }

      if (this.$screen.landscape) {
        this.$store.dispatch('orientationToChange', false)
      }
    },
    loadingError() {
      this.hasErrors = true
    }
  }
}
</script>
